import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ContentStyles, ContentStylesExt } from '../../utils/style';

const ContentWrapper = styled('div')`
  ${ContentStyles}
  ${ContentStylesExt}
`;

const ContentFromWp = ({ children }) => {
  const accordionListener = (event) => {
    if (event.target.classList.contains('accordion-title')) {
      let parentEl = event.target.parentNode;
      if (parentEl.classList.contains('accordion-open')) {
        parentEl.classList.remove('accordion-open');
      } else {
        parentEl.classList.add('accordion-open');
      }
    }
  };
  useEffect(() => {
    window.addEventListener('click', accordionListener);
    return () => {
      window.removeEventListener('click', accordionListener);
    };
  });

  return (
    <ContentWrapper>
      {children}
    </ContentWrapper>
  );
};

export default ContentFromWp;
