import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEOPage';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { wrapper, breakpoints } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import Parallax from '../../components/shared/Backgrounds/Parallax';
import ContentFromWp from '../../components/shared/ContentFromWP';
import ContactLinks from '../../components/Content/ContactUs/ContactLinks';

const WrapperDesktop = styled("div")`
  ${wrapper}
  .top-wrap {
    display: flex;
    flex-flow: row-wrap;
    align-items: center;
    .h1 {
      text-align: center;
    }
  }
  .featured-wrapper { 
    margin-right: 40px;
    flex: 0 0 294px;
  }
  .gatsby-image-wrapper {
    border-radius: 60px 30px 30px 30px;
  }
  @media (max-width: ${breakpoints.xs}) {
    .top-wrap {
      display: block;
      .h1 {
        text-align: left;
      }
    }
    .featured-wrapper {
      margin: 0 0 25px;
    }
    .gatsby-image-wrapper {
      border-radius: 30px 20px 20px 20px;
    } 
  }
`;


const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const featuredMedia = currentPage.featured_media;
  const metaTitle = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title)
    ? currentPage.yoast_meta.yoast_wpseo_title : currentPage.title + "- " + process.env.OWNER;
  const metaDesc = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc)
    ? currentPage.yoast_meta.yoast_wpseo_metadesc : '';
  const isContactPage = currentPage.wordpress_id === 15;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        <div className="top-wrap">
          {featuredMedia?.localFile?.childImageSharp?.fluid &&
          <div className="featured-wrapper" key="featured-wrapper">
            <Img fluid={featuredMedia.localFile.childImageSharp.fluid}
                 alt={(currentPage.featured_media.alt_text) ? currentPage.featured_media.alt_text : page_title} fadeIn={false} loading='eager' />
          </div>
          }
          <h1 className="h1 gradient" dangerouslySetInnerHTML={{ __html: page_title }} />
        </div>

        {isContactPage && <ContactLinks/>}

        <ContentFromWp>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }}/>
        </ContentFromWp>
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }  
      } 
    }
  }
`;
